/*
|--------------------------------------------------------------------------------
|                              Smoothscroll
|--------------------------------------------------------------------------------
|
| Smoothscroll generate by Lenis scroll
|
*/

export default {
  init: (Plyr) => {

    const players = Plyr.setup('.plyr__video-embed', {
      iconUrl: _DIR_IMG + '/utils/play.svg',
    });

  },
};
