/*
|--------------------------------------------------------------------------------
|                              Footer
|--------------------------------------------------------------------------------
|
| Footer
|
*/

export default {
  init: (gsap, ScrollTrigger) => {

    if (matchMedia('(min-width: 48rem)').matches) {
      const main = document.querySelector('main');
      const footer = document.querySelector('footer');
      const footerHeight = footer.clientHeight;

      gsap.set(footer, { yPercent: -30 });

      gsap.to(footer, {
        scrollTrigger: {
          trigger: main,
          start: 'bottom bottom',
          end: () => `+=${footerHeight}px`,
          markers: false,
          scrub: true,
        },
        yPercent: 0,
        ease: 'none'
      });
    }
  }
};




  


  

