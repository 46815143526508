/*
|--------------------------------------------------------------------------------
|                              Stack Animation
|--------------------------------------------------------------------------------
|
*/

export default {
  init: (gsap, ScrollTrigger) => {
    const images = document.querySelectorAll('[data-stack-effect]');
    const rotations = [6, -8, 12, -16];
    const translates = [3, -4, 4, -3];

    // Créez une timeline GSAP
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: ".b04_jer",
        start: 'top center',
        toggleActions: "play none none reverse"
      }
    });

    // Ajoutez l'animation des images à la timeline
    timeline.to(images, {
      duration: 1,
      y: 0,
      xPercent: (index) => translates[index % translates.length],
      rotation: (index) => rotations[index % rotations.length],
      ease: 'power2.out',
      stagger: {
        each: 0.3
      }
    });
  }
};




  

