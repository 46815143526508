/*
|--------------------------------------------------------------------------------
|                              Smoothscroll
|--------------------------------------------------------------------------------
|
| Smoothscroll generate by Lenis scroll
|
*/

export default {
  init: (lottie) => {

    lottie.loadAnimation({
      container: document.getElementById('feuilledroite'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: _DIR_IMG +'/lottie/feuille_droite.json'
    });

    lottie.loadAnimation({
      container: document.getElementById('feuillegauche'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: _DIR_IMG +'/lottie/feuille_gauche.json'
    });

    lottie.loadAnimation({
      container: document.getElementById('rightLeaf'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: _DIR_IMG +'/lottie/feuille_droite.json'
    });

    lottie.loadAnimation({
      container: document.getElementById('leftLeaf'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: _DIR_IMG +'/lottie/feuille_gauche.json'
    });

    lottie.loadAnimation({
      container: document.getElementById('pinkettedroite'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: _DIR_IMG +'/lottie/pinkette_droite.json'
    });

    lottie.loadAnimation({
      container: document.getElementById('pinkettegauche'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: _DIR_IMG +'/lottie/pinkette_gauche.json'
    });

  },
};
