/*
|--------------------------------------------------------------------------------
|                              Smoothscroll
|--------------------------------------------------------------------------------
|
| data scroll speed 
|
*/

export default {
    init: (gsap, ScrollTrigger) => {

      gsap.utils.toArray("[data-scroll-rotate]").forEach(el => {
        let rotateSpeed = parseFloat(el.getAttribute("data-scroll-rotate"));
    
        gsap.to(el, {
            rotate: () => (1 - rotateSpeed),
            ease: "none",
            scrollTrigger: {
                trigger: el,
                start: "top bottom",
                end: "max",
                scrub: 1,
                invalidateOnRefresh: true,
                markers: false
            }
        });
    });
    
    
      
  }
};
  


  

