/*
|
| Importing Libs 
|------------------
*/
import gsap from "gsap";
import lottie from "lottie-web";
import Plyr from "plyr";
import CustomEase from "@lib/gsap-pro/CustomEase";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";

import LazyLoad from "vanilla-lazyload";
import Accordion from "accordion-js";
import Swiper from "swiper";
import { Navigation, Autoplay } from "swiper/modules";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(ScrollToPlugin);

/*
|
| Importing Components
|-----------------------
*/
// import CookieManager from "@components/cookies";
import Lenis from "lenis";
import footer from "@components/footer";
import smooth from "@components/smooth";
import stack from "@components/stack-img.js";
import lottieAnimation from "@components/lottie.js";
import video from "@components/video.js";
import b08_jer from "@components/blocks/b08_jer.js";
import scrollSpeed from "@components/scroll-speed";
import scrollRotate from "@components/scroll-rotate";
import customGoogleMap from "@components/google-map.js";
import "leaflet";
import "leaflet.markercluster";

/*
|
| Importing Utils
|-------------------
*/
// import Config from '@utils/config.js';
import Router from "@utils/router.js";

/*
|
| Importing App files
|----------------------
*/
import * as app from "@components/global.js";
import main from "@pages/main.js";
import home from "@pages/home.js";
import news from "@pages/archive-news.js";
import article from "@pages/single-news.js";
import projects from "@pages/archive-projects.js";
// import contact from "@pages/contact.js";

/*
|
| Routing
|----------
*/
const routes = new Router([
  {
    file: main,
    dependencies: [app, gsap, ScrollTrigger, CustomEase, LazyLoad, Accordion, Swiper, Navigation, Autoplay],
  },
  {
    file: smooth,
    dependencies: [gsap, Lenis, ScrollTrigger],
  },
  {
    file: scrollSpeed,
    dependencies: [gsap, ScrollTrigger],
    resolve: ".b02_jer",
  },
  {
    file: scrollRotate,
    dependencies: [gsap, ScrollTrigger],
    resolve: ".b02_jer",
  },
  {
    file: b08_jer,
    resolve: ".b08_jer",
  },
  {
    file: video,
    dependencies: [Plyr],
    resolve: ".b11",
  },
  {
    file: stack,
    dependencies: [gsap, ScrollTrigger],
    resolve: ".b04_jer",
  },
  {
    file: lottieAnimation,
    dependencies: [lottie],
  },
  {
    file: footer,
    dependencies: [gsap, ScrollTrigger],
  },
  {
    file: home,
    dependencies: [app],
    resolve: "#page-home",
  },
  {
    file: news,
    dependencies: [app],
    resolve: "#archive-news",
  },
  {
    file: article,
    dependencies: [app, gsap, ScrollTrigger],
    resolve: "#single-article",
  },
  {
    file: projects,
    dependencies: [app],
    resolve: "#archive-projects",
  },
  // {
  //   file: contact,
  //   dependencies: [app, gsap, ScrollTrigger, customGoogleMap],
  //   resolve: "#page-contact",
  // },
]);

/*
|
| Run
|------
*/
(($) => {
  routes.load();
})(jQuery);
