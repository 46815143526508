export default {
  init: (app) => {
    /*
        |
        | Constants
        |------------
        */
    const $body = $("body");
  },
};
