// import { FreeMode } from "swiper/modules";

export default {
  init: (app, gsap, ScrollTrigger, CustomEase, LazyLoad, Accordion, Swiper, Navigation, Autoplay) => {
    /*Constants*/
    const $window = $(window),
      $body = $("body"),
      $bodyOffset = $body.offset(),
      $preloader = $("#preloader"),
      $cookieBanner = $(".cookie-banner"),
      $cookieAccept = $cookieBanner.find(".item-accept"),
      $cookieRefuse = $cookieBanner.find(".item-refuse"),
      $header = document.querySelector("#header"),
      $burger = $(".burger"),
      $scrollTo = $(".scrollto"),
      $scrollUp = $(".scrollup"),
      $themeElement = $("[data-theme]"),
      $themeColor = $(".theme-color"),
      $colorSpan = $themeColor.find("span");
    /*Easings*/
    CustomEase.create("easeCustom", "M0,0 C0.4,0 0.2,1 1,1");
    CustomEase.create("easeSmooth", "M0,0 C0.19,1 0.22,1 1,1");

    /* Preloader*/
    $themeElement.attr("data-theme", sessionStorage.theme);

    if (sessionStorage.getItem("loaded_once") === null) {
      sessionStorage.setItem("loaded_once", "loaded_once");
      $preloader.find(".preloader-full").addClass("active");
      let $loadingFill = $preloader.find(".loading-fill");

      $window.on("load", function () {
        gsap.to($preloader, 0.5, {
          "--cp-coord-y": "200%",
          ease: "Linear.easeNone",
          delay: 1.1,
          onComplete: () => $preloader.remove(),
          addCallback: () => app.dispachEvent($body, "loaderEnd"),
        });
        gsap.to($loadingFill, 1, {
          width: "100%",
          ease: "Linear.easeNone",
        });
      });
    } else {
      // $preloader.remove();
      // app.dispachEvent($body, "loaderEnd");
      $window.on("load", function () {
        gsap.to($preloader, 0.2, {
          onComplete: () => $preloader.remove(),
          addCallback: () => app.dispachEvent($body, "loaderEnd"),
        });
      });
    }

    /*On Window Loaded*/
    $body.on("loaderEnd", () => {
      /*LazyLoad*/
      function lazyParent(el) {
        const lazyContainer = el.closest(".lazy-container");
        lazyContainer.classList.add("is-load");
        ScrollTrigger.refresh();
      }

      window.lazy = new LazyLoad({
        unobserve_entered: true,
        callback_loaded: lazyParent,
      });

      /*Burger Menu*/
      $burger.on("click", function () {
        $body.toggleClass("is-menu");
      });

      function checkDevice() {
        if ("matchMedia" in window) {
          if (window.matchMedia("(min-width:48rem)").matches) {
            $body.removeClass("is-menu");
          }
        }
      }
      window.addEventListener("resize", checkDevice, false);
      const paths = [...document.querySelectorAll("path.path-anim")];
      paths.forEach((el) => {
        const svgEl = el.closest("svg");
        const pathTo = el.dataset.pathTo;
        gsap
          .timeline({
            scrollTrigger: {
              trigger: svgEl,
              start: "top 100%",
              end: "top 20%",
              scrub: true,
            },
          })
          .to(el, {
            ease: "none",
            attr: { d: pathTo },
          });
      });

      /* Header on Scroll */

      const hideHeader = gsap.to($header, {
        yPercent: -100,
        paused: true,
        duration: 0.2,
      });
      // .progress(1);

      ScrollTrigger.matchMedia({
        "(min-width: 48rem)": function () {
          ScrollTrigger.create({
            start: "top -80",
            end: 99999,
            onUpdate: (self) => {
              self.direction === 1 && !$header.querySelector("._show") ? hideHeader.play() : hideHeader.reverse();
            },
            toggleClass: { className: "is-scrolled", targets: $header },
          });
        },
        // "(max-width: 47rem)": function () {
        //   hideHeader.play();
        // },
      });

      let card = document.querySelector("#card");
      const hideCol = gsap.to(card.querySelector(".col"), {
        width: 0,
        opacity: 0,
        paddingLeft: 0,
        textWrap: "nowrap",
        paused: true,
        duration: 0.2,
      });
      ScrollTrigger.create({
        start: "top top",
        end: 99999,
        onUpdate: (self) => {
          document.documentElement.scrollTop >= window.innerHeight ? hideCol.play() : hideCol.reverse();
        },
      });

      card.addEventListener("mouseenter", (e) => {
        hideCol.reverse();
      });

      /* Scrollto */
      // $scrollTo.on("click", function () {
      //   gsap.to(window, { duration: 0.8, ease: "easeCustom", scrollTo: $window.height() });
      // });

      /* Scroll to top */
      // $scrollUp.on("click", function () {
      //   gsap.to(window, { duration: 1.2, ease: "easeCustom", scrollTo: $bodyOffset.top });
      // });

      /* Switch Theme Color */
      // if (sessionStorage.theme == "dark") {
      //   $colorSpan.text("Light");
      // } else {
      //   $colorSpan.text("Dark");
      // }

      // $themeColor.on("click", function () {
      //   if (sessionStorage.theme != "dark") {
      //     $themeElement.attr("data-theme", "dark");
      //     sessionStorage.theme = "dark";
      //     $colorSpan.text("Light");
      //   } else {
      //     $themeElement.attr("data-theme", "light");
      //     sessionStorage.theme = "light";
      //     $colorSpan.text("Dark");
      //   }
      // });
    });

    /* Cookie Manager */
    // new CookieManager($cookieBanner, {
    //   name: "local_cookie_use",
    //   duration: 30,
    //   buttons: {
    //     accept: $cookieAccept,
    //     refuse: $cookieRefuse,
    //     // close: $cookieClose
    //   },
    //   onAccept: () => {},
    //   onRefuse: () => {},
    // });

    let childMenus = document.querySelectorAll(".children");
    childMenus.forEach((child) => {
      child.addEventListener("mouseup", (e) => {
        if (e.target == e.target.closest(".children") || e.target == e.target.closest("a")) child.classList.remove("_show");
      });
    });

    let parentItems = document.querySelectorAll(".menu-item-has-children");
    parentItems.forEach((item) => {
      if (item.dataset.child) {
        item.addEventListener("click", (event) => {
          event.preventDefault();
          let child = document.querySelector(`[data-parent="${item.dataset.child}"]`);
          let childrenNot = document.querySelectorAll(`.children:not([data-parent="${item.dataset.child}"])`);
          childrenNot.forEach((childNot) => {
            childNot.classList.remove("_show");
          });
          child.classList.toggle("_show");
        });
      }
    });

    let btnSelects = document.querySelectorAll(".btn-select");
    btnSelects.forEach((btnSelect) => {
      btnSelect.addEventListener("click", (event) => {
        let blockId = btnSelect.dataset.block;

        let option = event.target.closest("[data-filter]");
        if (option && blockId) {
          if (option.dataset.filter !== "none") {
            btnSelect.querySelector('[data-filter="none"]').classList.add("_active");

            let filterText = option.innerText;
            let filterId = option.dataset.filter;

            let eventsYes = document.querySelectorAll(`#${blockId} .event[data-type="${filterId}"]`);
            eventsYes.forEach((event) => event.classList.remove("badType"));
            let eventsNo = document.querySelectorAll(`#${blockId} .event:not([data-type="${filterId}"])`);
            eventsNo.forEach((event) => event.classList.add("badType"));

            btnSelect.querySelector(".text").innerText = filterText;
          } else {
            let events = document.querySelectorAll(`#${blockId} .event`);
            btnSelect.querySelector('[data-filter="none"]').classList.remove("_active");
            btnSelect.querySelector(".text").innerText = "Filtrer par type d'actions  ";

            events.forEach((event) => event.classList.remove("badType"));
          }

          let moreButton = document.querySelector(`[data-more="${blockId}"]`);
          if (moreButton) {
            let stillHiddenEvents = document.querySelectorAll(`#${blockId} .event:not(.badType).d-none`);
            if (stillHiddenEvents.length) {
              moreButton.classList.remove("_disabled");
            } else {
              moreButton.classList.add("_disabled");
            }
          }
        }

        let region = event.target.closest("[data-region]");
        if (region && blockId) {
          setActive(blockId, region);
        }

        let listOptions = btnSelect.querySelector(".options");
        listOptions.classList.toggle("_show");
        ScrollTrigger.refresh();
      });
    });

    let franceMaps = document.querySelectorAll(".france");
    franceMaps.forEach((franceMap) => {
      let blockId = franceMap.dataset.block;

      let regionMaps = franceMap.querySelectorAll(".map-region");
      regionMaps.forEach((regionMap) => {
        regionMap.addEventListener("click", (e) => {
          let region = document.querySelector(`#${blockId} .options [data-region="${regionMap.dataset.region}"]`);
          if (region) setActive(blockId, region);
        });
      });
    });

    function setActive(blockId, region) {
      if (region.dataset.region) {
        let regionText = region.innerText;
        let regionId = region.dataset.region;

        let mapRegions = document.querySelectorAll(`#${blockId} .map-region`);
        mapRegions.forEach((mapRegion) => {
          if (mapRegion.getAttribute("data-region") === regionId) {
            mapRegion.classList.add("_active");
          } else {
            mapRegion.classList.remove("_active");
          }
        });

        let listRegions = document.querySelectorAll(`#${blockId} .list-region`);
        listRegions.forEach((listRegion) => {
          if (listRegion.getAttribute("data-region") === regionId) {
            listRegion.classList.add("_active");
          } else {
            listRegion.classList.remove("_active");
          }
        });

        let btnSelect = document.querySelector(`#${blockId} .btn-select`);
        btnSelect.querySelector(".text").innerText = regionText;

        let stickyParentGrid = document.querySelector(`#${blockId} .grid.component-gap.align-center`);
        if (stickyParentGrid) stickyParentGrid.classList.replace("align-center", "align-start");
      }
    }

    let accordions = document.querySelectorAll(".accordion-container");
    new Accordion(Array.from(accordions), {
      onOpen: function (element) {
        ScrollTrigger.refresh();
      },
      onClose: function (element) {
        ScrollTrigger.refresh();
      },
    });

    let swipersClassic3 = document.querySelectorAll(".swiper-classic-3");
    swipersClassic3.forEach((swiper) => {
      new Swiper(swiper, {
        modules: [Navigation],
        grabCursor: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        slidesPerView: 1,
        spaceBetween: 8,
        slideVisibleClass: "swiper-slide-visible",
        watchSlidesProgress: true,
        breakpoints: {
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        },
      });
    });

    let swipersClassic2 = document.querySelectorAll(".swiper-classic-2");
    swipersClassic2.forEach((swiper) => {
      new Swiper(swiper, {
        modules: [Navigation],
        grabCursor: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        slidesPerView: 1,
        spaceBetween: 8,
        slideVisibleClass: "swiper-slide-visible",
        watchSlidesProgress: true,
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
        },
      });
    });

    let swiperTile = document.querySelectorAll(".swiper-tile");
    swiperTile.forEach((swiper) => {
      new Swiper(swiper, {
        modules: [Navigation],
        grabCursor: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        slidesPerView: 1,
        spaceBetween: 8,
        breakpoints: {
          768: {
            slidesPerView: 3,
          },
        },
      });
    });

    let swiperLogos = document.querySelectorAll(".swiper-logos");
    swiperLogos.forEach((swiper) => {
      new Swiper(swiper, {
        modules: [Autoplay],
        grabCursor: false,
        simulateTouch: false,
        allowTouchMove: false,
        slidesPerView: 2,
        loop: true,
        freeMode: true,
        loopAddBlankSlides: true,
        speed: 5000,
        autoplay: {
          delay: 0,
        },
        breakpoints: {
          768: {
            slidesPerView: 7,
            speed: 5000,
          },
        },
      });
    });

    let swipersMenu = document.querySelectorAll(".swiper-menu");
    swipersMenu.forEach((swiper) => {
      new Swiper(swiper, {
        modules: [Navigation],
        grabCursor: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        slidesPerView: 4,
        spaceBetween: 15,
      });
    });

    let swipersMenuMobile = document.querySelectorAll(".swiper-menu-mobile");
    swipersMenuMobile.forEach((swiper) => {
      new Swiper(swiper, {
        slidesPerView: "auto",
        spaceBetween: 30,
      });
    });

    let accordionsMenu = document.querySelectorAll(".accordion-container-menu");
    new Accordion(Array.from(accordionsMenu));

    const numbersObserver = new window.IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.target.classList.contains("anim")) {
              entry.target.classList.remove("anim");
              gsap.from(entry.target, {
                innerText: 0,
                duration: 2,
                repeat: 0,
                snap: {
                  innerText: 1,
                },
              });
            }
          }
        });
      },
      {
        root: null,
        threshold: 0.75,
      }
    );

    let animatedNumbers = document.querySelectorAll(".number");
    for (const animatedNumber of animatedNumbers) {
      numbersObserver.observe(animatedNumber);
    }

    let moreButtons = document.querySelectorAll("[data-more]");
    moreButtons.forEach((moreButton) => {
      moreButton.addEventListener("click", (e) => {
        e.preventDefault();
        let blockId = moreButton.dataset.more;
        let hiddenItems = [];
        let revealedItems = 4;

        if (document.querySelector(`#${blockId} .tile`)) {
          hiddenItems = document.querySelectorAll(`#${blockId} .tile.d-none`);
          revealedItems = 3;
        } else if (document.querySelector(`#${blockId} .event`)) {
          hiddenItems = document.querySelectorAll(`#${blockId} .event.d-none:not(.badType)`);
          revealedItems = 3;
        }

        if (hiddenItems.length <= revealedItems) moreButton.classList.add("_disabled");
        for (let i = 0; i < Math.min(hiddenItems.length, revealedItems); i++) {
          hiddenItems[i].classList.remove("d-none");
        }
        ScrollTrigger.refresh();
      });
    });

    let maps = document.querySelectorAll(".map");
    maps.forEach((map) => {
      let blockId = map.dataset.block;
      let popups = {};

      let $map = L.map(map);
      L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png").addTo($map);

      let evenements = document.querySelectorAll(`#${blockId} .event:not(.badType)`);
      evenements.forEach((evenement) => {
        evenement.addEventListener("click", (e) => {
          popups[evenement.dataset.marker].openOn($map);
          $map.flyTo([evenement.dataset.lat, evenement.dataset.lng], 12, { duration: 0.1 });
          let lastEvents = document.querySelectorAll(`#${blockId} .event._active`);
          lastEvents.forEach((lastEvt) => lastEvt.classList.remove("_active"));

          let lastMarkers = document.querySelectorAll(`#${blockId} .leaflet-marker-icon._active`);
          lastMarkers.forEach((lastMrk) => lastMrk.classList.remove("_active"));
          setTimeout(() => {
            let marker = document.querySelector(`#${blockId} .leaflet-marker-icon.event-${evenement.dataset.marker}`);
            marker.classList.add("_active");
          }, 250);

          evenement.classList.add("_active");
        });
      });

      let markers = L.markerClusterGroup();
      $map.addLayer(markers);

      printMarkers(blockId, $map, markers, popups);

      let btnFilters = document.querySelectorAll(".btn-filter");
      btnFilters.forEach((btnFilter) => {
        btnFilter.addEventListener("click", (event) => {
          let blockId = btnFilter.dataset.block;

          let option = event.target.closest("[data-filter]");
          if (option && blockId) {
            if (option.dataset.filter !== "none") {
              btnFilter.querySelector('[data-filter="none"]').classList.add("_active");

              let filterText = option.innerText;
              let filterId = option.dataset.filter;

              let eventsYes = document.querySelectorAll(`#${blockId} .event[data-type="${filterId}"]`);
              eventsYes.forEach((event) => event.classList.remove("badType"));
              let eventsNo = document.querySelectorAll(`#${blockId} .event:not([data-type="${filterId}"])`);
              eventsNo.forEach((event) => event.classList.add("badType"));

              btnFilter.querySelector(".text").innerText = filterText;
            } else {
              let events = document.querySelectorAll(`#${blockId} .event`);
              btnFilter.querySelector('[data-filter="none"]').classList.remove("_active");
              btnFilter.querySelector(".text").innerText = "Filtrer par type d'actions  ";
              events.forEach((event) => event.classList.remove("badType"));
            }
            let currentEvent = document.querySelector(`#${blockId} .event._active`);
            if (currentEvent) currentEvent.classList.remove("_active");
            printMarkers(blockId, $map, markers, popups);
          }

          let listOptions = btnFilter.querySelector(".options");
          listOptions.classList.toggle("_show");
          ScrollTrigger.refresh();
        });
      });
    });

    function printMarkers(blockId, map, markers, popups) {
      let bounds = [];
      markers.clearLayers();
      document.querySelectorAll(`#${blockId} .event:not(.badType)`).forEach(function (el) {
        let lat = parseFloat(el.dataset.lat);
        let lng = parseFloat(el.dataset.lng);

        let icon = L.icon({
          iconUrl: _DIR_IMG + "/utils/icon_pointer.svg",
          iconSize: [29, 36],
          iconAnchor: [14, 35],
          className: "event-" + el.dataset.marker,
        });

        let popup = L.popup(L.latLng(lat, lng), { content: el.dataset.title });
        popups[el.dataset.marker] = popup;

        bounds.push(L.latLng(lat, lng));

        let marker = L.marker([lat, lng], { icon: icon }).bindPopup(popup);
        marker.event = el.dataset.marker;
        marker.uuid = el.dataset.uuid;

        marker.addEventListener("click", (e) => {
          let evenement = document.querySelector(`#${blockId} .event[data-marker="${e.target.event}"]`);
          if (evenement) {
            let lastEvents = document.querySelectorAll(`#${blockId} .event._active`);
            lastEvents.forEach((lastEvt) => lastEvt.classList.remove("_active"));

            let lastMarkers = document.querySelectorAll(`#${blockId} .leaflet-marker-icon._active`);
            lastMarkers.forEach((lastMrk) => lastMrk.classList.remove("_active"));

            marker._icon.classList.add("_active");
            evenement.classList.add("_active");
            // evenement.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
          }
        });

        map.fitBounds(bounds);
        markers.addLayer(marker);
      });
    }

    const pinnedImageWrappers = document.querySelectorAll(".block_pinned");
    pinnedImageWrappers.forEach((wrapper) => {
      const inner = wrapper.querySelector(".pinned-inner");
      let sections = gsap.utils.toArray(".thumbnail");

      let scrollTween = gsap.to(inner, {
        x: -(inner.scrollWidth - document.documentElement.clientWidth),
        ease: "none",
        scrollTrigger: {
          trigger: wrapper,
          start: "center center",
          end: () => `+=${inner.scrollWidth - document.documentElement.clientWidth}`,
          pin: wrapper,
          scrub: true,
          invalidateOnRefresh: true,
        },
      });

      sections.forEach((image) => {
        // Récupérer la valeur de rotation depuis l'attribut data-horizontal-rotate
        const rotationValue = image.getAttribute("data-horizontal-rotate") || 0;

        gsap.fromTo(
          image,
          { rotation: -rotationValue },
          {
            rotation: rotationValue, // Utiliser la valeur récupérée pour la rotation
            ease: "none", // Pas d'effet d'accélération pour un scrub linéaire
            scrollTrigger: {
              trigger: image,
              containerAnimation: scrollTween,
              start: "left 100%", // Inversion du start à gauche
              end: "right 0%", // Inversion du end à droite
              scrub: true, // Lier l'animation au défilement pour un effet fluide
            },
          }
        );
      });
    });
  },
};
