/*
|--------------------------------------------------------------------------------
|                              Smoothscroll
|--------------------------------------------------------------------------------
|
| Smoothscroll generate by Lenis scroll
|
*/

export default {
  init: (gsap, Lenis, ScrollTrigger) => {
    const lenis = new Lenis();

    lenis.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);

    window.lenis = lenis;
  },
};
