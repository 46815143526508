/*
|--------------------------------------------------------------------------------
|                              Smoothscroll
|--------------------------------------------------------------------------------
|
| data scroll speed 
|
*/

export default {
  init: (gsap, ScrollTrigger) => {
    gsap.utils.toArray("[data-scroll-speed]").forEach((el) => {
      let speed = parseFloat(el.getAttribute("data-scroll-speed"));

      gsap.to(el, {
        y: () => (1 - speed) * 3000,
        ease: "none",
        scrollTrigger: {
          trigger: el,
          start: "top bottom",
          end: "max",
          scrub: 1,
          invalidateOnRefresh: true,
          markers: false,
        },
      });
    });
  },
};
